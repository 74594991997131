import { Image, Text } from "@react-three/drei"
import { useThree } from "@react-three/fiber"
import { useState } from "react"


export const projects = [
  {
    title: "Fit-Lyfe",
    url: "https://fit-lyfe.club/",
    image: "projects/fitlyfe.png",
    description: "This project features an innovative web application aimed at transforming fitness education and personal training with an interactive 3D human anatomy model. Users tap muscles for exercise suggestions, ideal for gym-goers. It's mobile-friendly and runs on a custom API using AWS technologies (RDS PostgreSQL, Lambda, API Gateway) for a seamless experience.",
  },
  {
    title: "Wrapify",
    url: "https://wrap-ify.co/",
    image: "projects/wrapify.png",
    description: "Preview & experiment how your car would look in a customisable colour (More models to come!)",
  },
  {
    title: "Exercise API",
    url: "https://exercise-api-zeta.vercel.app/",
    image: "projects/apiscreenshot.png",
    description: "A RESTful exercise API that leverages AWS RDS for robust data storage, AWS Lambda for serverless computing power, and API Gateway for seamless API management and deployment",
  },

]
const Project = ({ setSection, ...props }) => {
  const { project } = props
  const [hovered, setHovered] = useState(false);


  const handlePlaneClick = () => {
    if (project.title === "Portfolio") {
      setSection(2);
    } else if (project.url) {
      window.open(project.url, "_blank");
    }
  };

  const onHover = (e) => {
    e.stopPropagation();
    setHovered(true);
    document.body.style.cursor = 'pointer';
  };


  const onUnhover = (e) => {
    e.stopPropagation();
    setHovered(false);
    document.body.style.cursor = 'default';
  };

  const scale = hovered ? [1.6, 1.6, 1.6] : [1.5, 1.5, 1.5];

  return (
    <group {...props} scale={scale} position-y={-1} >
      <mesh
        position-z={0.001}
        onClick={handlePlaneClick}
        onPointerOver={onHover}
        onPointerOut={onUnhover}
      >
        <planeGeometry args={[2.2, 2]} />
        <meshBasicMaterial color={"black"} transparent opacity={0.8} />
      </mesh>

      <Image
        scale={[2, 1.2, 1]}
        url={project.image}
        toneMapped={false}
        position-y={0.3}
        position-z={0.002}
      />
      <Text
        maxWidth={2}
        anchorX={"left"}
        anchorY={"top"}
        fontSize={0.2}
        position={[-1, -0.4, 0.002]}
      >
        {project.title.toUpperCase()}
      </Text>
      <Text
        maxWidth={2}
        anchorX={"left"}
        anchorY={"top"}
        fontSize={0.08}
        position={[-1, -0.65, 0.002]}
      >
        {project.description}
      </Text>

    </group>
  )
}

export const Projects = ({ setSection }) => {

  const { viewport } = useThree()

  return (
    <group position-y={-viewport.height * 2 + 1} >
      {
        (() => {
          const numProjects = projects.length;
          const spacing = 3.5;
          const totalWidth = numProjects * spacing - spacing;
          const centerOffset = totalWidth / 2;

          return projects.map((project, idx) => (
            <group key={"project_" + idx} position={[(idx * spacing) - centerOffset, 0, -3]}>
              <Project project={project} setSection={setSection} />
            </group>
          ));
        })()
      }
    </group>
  )
}