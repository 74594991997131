/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 keyboard.glb 
Author: thethieme (https://sketchfab.com/thethieme)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/keyboard-cc7e7ed38d9a469ea499893295bc767a
Title: Keyboard
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Keyboard(props) {
  const { nodes, materials } = useGLTF('models/keyboard.glb')
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={0.18}>
        <mesh geometry={nodes.defaultMaterial.geometry} material={materials.KeyBoard_01} rotation={[Math.PI / 2, 0, 0]} />
      </group>
    </group>
  )
}

useGLTF.preload('models/keyboard.glb')
