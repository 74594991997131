

import React from 'react';
import { useSpring, animated, useSprings } from 'react-spring';

const Section = ({ children, uniqueClass }) => {
  const props = useSpring({
    to: { opacity: 1, transform: 'translateY(0)' },
    from: { opacity: 0, transform: 'translateY(50px)' },
    delay: 600,
    config: { duration: 1000 }
  });

  return (
    <animated.section style={props} className={`section ${uniqueClass}`} >
      {children}
    </animated.section>
  );
};


export const Interface = ({ setSection }) => {
  return (

    <>

      <AboutSection setSection={setSection} />

      <SkillsSection />

      <Section >

      </Section>


    </>

  )
}



const AboutSection = ({ setSection }) => {
  const textProps = useSpring({
    to: { opacity: 1, transform: 'translateY(0)' },
    from: { opacity: 0, transform: 'translateY(25px)' },
    delay: 1500,
    config: { duration: 1000 }
  });

  const buttonProps = useSpring({
    ...textProps,
    delay: 2000
  });

  return (
    <Section uniqueClass="about">
      <div>
        <h1>Hi, I'm<br /><span>Chris Ward</span></h1>
        <animated.p style={textProps}>I'm an aspiring Junior Software Developer recently diving into the tech world. I'm passionate about creating intuitive and efficient software solutions. Although I'm at the beginning of my professional journey, my portfolio demonstrates my commitment to learning and my excitement for each new coding challenge. Let's explore the possibilities of technology together!
          <br />
          <br />

          <a rel="noopener noreferrer" target="_blank" href="https://github.com/ChrisW91">
            <img className={'socials'} alt='' src="/images/github.png" />
          </a>
          <a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/in/chris-ward91/">
            <img className={'socials'} alt='' src="/images/Linkedin.png" />
          </a>
          <br />
          chris@christopherward.site
        </animated.p>
        <animated.button style={buttonProps} >
          <a href="mailto:chris@christopherward.site">Get in touch!</a>
        </animated.button>

      </div>
    </Section>
  );
};
<a href="mailto:chris@christopherward.site">Contact Me</a>

const hardSkills = [
  {
    title: "Three.js",
    level: 85,
  },
  {
    title: "React Three Fiber",
    level: 85,
  },
  {
    title: "React",
    level: 75,
  },
  {
    title: "Javascript",
    level: 80,
  },
  {
    title: " Node.js",
    level: 85,
  },
  {
    title: "Postgres",
    level: 80,
  },
  {
    title: "Python",
    level: 50,
  },
  {
    title: "MongoDB",
    level: 65,
  },
  {
    title: "Git",
    level: 65,
  },

]


const SkillsSection = () => {
  const springs = useSprings(
    hardSkills.length,
    hardSkills.map((skill, idx) => ({
      to: { scaleX: 1, opacity: 1 },
      from: { scaleX: 0, opacity: 0 },
      delay: 1000 + idx * 200,
      config: { duration: 1000 }
    }))
  );

  return (
    <Section uniqueClass="skills">
      <div>
        <h2>Skills</h2>
        <div>
          {springs.map((props, idx) => (
            <div key={idx}>
              <animated.h3 style={props}>{hardSkills[idx].title}</animated.h3>

            </div>
          ))}
        </div>
      </div>
    </Section>
  );
};

