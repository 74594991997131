/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 react_logo.glb 
Author: xenadus (https://sketchfab.com/xenadus)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/react-logo-76174ceeba96487f9863f974636f641e
Title: React logo
*/
import { useSpring, animated } from '@react-spring/three';
import React, { useRef, useState, useEffect } from 'react'
import { useGLTF } from '@react-three/drei'

export function ReactLogo(props) {
  const { nodes, materials } = useGLTF('models/react_logo.glb')
  return (
    <group {...props} dispose={null}>
      <group scale={0.001}>
        <mesh
          geometry={nodes['React-Logo_Material002_0'].geometry}
          material={materials['Material.002']}
          // rotation={[0, 0, -Math.PI / 2]}
          scale={[39.166, 39.166, 52.734]}
        />
      </group>
    </group>
  )
}

export function AnimatedReactLogo() {

  const getRandomVelocity = () => {
    return [
      Math.random() * 0.1 - 0.05,
      Math.random() * 0.1 - 0.05,
      Math.random() * 0.1 - 0.05
    ];
  };

  const [position, setPosition] = useState([2, -4, 0]);
  const [rotation, setRotation] = useState([0, 0, 0]);
  const [velocity, setVelocity] = useState(getRandomVelocity());


  const bounds = {
    minX: -4,
    maxX: 4,
    minY: 0,
    maxY: -7,
    minZ: -2,
    maxZ: 2
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setPosition(prevPosition => {
        let [x, y, z] = prevPosition;
        let [vx, vy, vz] = velocity;

        // Update position based on velocity
        x += vx;
        y += vy;
        z += vz;

        // Check for collisions and reverse velocity if needed
        if (x <= bounds.minX || x >= bounds.maxX) { vx = -vx; }
        if (y <= bounds.minY || y >= bounds.maxY) { vy = -vy; }
        if (z <= bounds.minZ || z >= bounds.maxZ) { vz = -vz; }

        // Update the velocity state
        setVelocity([vx, vy, vz]);

        return [x, y, z];
      });
    }, 30);

    return () => clearInterval(interval);
  }, [velocity]);


  useEffect(() => {
    const rotationInterval = setInterval(() => {
      setRotation(prevRotation => {
        let [rx, ry, rz] = prevRotation;


        rx += 0.02;
        ry += 0.02;
        rz += 0.02;

        return [rx, ry, rz];
      });
    }, 20);

    return () => clearInterval(rotationInterval);
  }, []);






  const props = useSpring({
    to: { position, rotation },
    config: { tension: 170, friction: 26 }, // Short duration for a more responsive feel
  });

  return <animated.group {...props}>
    <ReactLogo />
  </animated.group>;
}

useGLTF.preload('models/react_logo.glb')
