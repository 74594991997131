


export const Menu = (props) => {
  const { onSectionChange, menuOpened, setMenuOpened } = props

  const MenuButton = (props) => {
    const { label, onClick } = props
    return (
      <button
        onClick={onClick}
      >
        {label}
      </button>
    )
  }

  return (
    <>
      <button
        className="menu-button"
        onClick={() => setMenuOpened(!menuOpened)}
      >
        <div
          className={`bar top-bar ${menuOpened ? 'rotate-45' : ''}`}
        />
        <div
          className={`bar middle-bar ${menuOpened ? 'hidden' : ''}`}
        />
        <div
          className={`bar bottom-bar ${menuOpened ? '-rotate-45' : ''}`}
        />
      </button>
      <div className={`menuContainer ${menuOpened ? 'menuOpened' : 'menuClosed'}`}>
        <div className="content">
          <MenuButton label="About" onClick={() => onSectionChange(0)} />
          <MenuButton label="Skills" onClick={() => onSectionChange(1)} />
          <MenuButton label="Projects" onClick={() => onSectionChange(2)} />
          {/* <MenuButton label="Contact" onClick={() => onSectionChange(3)} /> */}
        </div>
      </div>
    </>
  )
}