import ReactDOM from 'react-dom/client';
import './index.css';
import { Canvas } from '@react-three/fiber';
import Scene from './Scene';
import { Scroll, ScrollControls } from '@react-three/drei';
import { Interface } from './components/Interface';
import { useEffect, useState } from 'react';
import { ScrollManager } from './components/ScrollManager';
import { Menu } from './components/Menu';
import { MotionConfig } from 'framer-motion';
import { framerMotionConfig } from './FramerMotionConfig';
import MatrixEffect from './components/MatrixBG';


const App = () => {

  const [section, setSection] = useState(0)
  const [menuOpened, setMenuOpened] = useState(false)

  useEffect(() => {
    setMenuOpened(false)
  }, [section])



  return (
    <>
      <MatrixEffect />

      <MotionConfig transition={{
        ...framerMotionConfig,
      }}>
        <Canvas
          shadows
          camera={{
            fov: 45,
            near: 0.1,
            far: 100,
            position: [0, 0, 7],
          }}
        >

          <ScrollControls pages={3} damping={0} >

            <ScrollManager section={section} onSectionChange={setSection} />

            <Scroll>
              <Scene section={section} menuOpened={menuOpened} setSection={setSection} />
            </Scroll>

            <Scroll html >
              <Interface setSection={setSection} />
            </Scroll>

          </ScrollControls>

        </Canvas>
        <Menu
          onSectionChange={setSection}
          menuOpened={menuOpened}
          setMenuOpened={setMenuOpened}
        />

      </MotionConfig>

    </>

  );
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);