import { useScroll } from "@react-three/drei"
import { useEffect, useRef } from "react"
import { gsap } from "gsap/gsap-core"
import { useFrame } from "@react-three/fiber"



export const ScrollManager = (props) => {
  const { section, onSectionChange } = props

  const data = useScroll()
  const lastScroll = useRef(0)
  const isAnimating = useRef(false)

  data.fill.classList.add("page-scroll")



  useEffect(() => {
    gsap.to(data.el, {
      duration: 1,
      scrollTop: section * data.el.clientHeight,
      onStart: () => {
        isAnimating.current = true
      },
      onComplete: () => {
        isAnimating.current = false
      }
    })

  }, [section])

  useFrame(() => {
    if (isAnimating.current) {
      lastScroll.current = data.scroll.current
      return
    }

    const curSection = Math.floor(data.scroll.current * data.pages)

    // page scroll 1-2 2-1
    if (data.scroll.current > lastScroll.current && curSection === 0) {
      onSectionChange(1)
    }
    else if (
      data.scroll.current < lastScroll.current && data.scroll.current < 1 / (data.pages - 1)
    ) {
      onSectionChange(0)
    }


    lastScroll.current = data.scroll.current
  })

  return null
} 