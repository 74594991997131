
// import { useControls } from "leva"
// import { OrbitControls } from "@react-three/drei"
import { Avatar } from "./components/Avatar"
import { Desk } from "./components/office/Desk"
import { Monitor } from "./components/office/Monitor"
// import { Mouse } from "./components/office/Pc-mouse"
import { Keyboard } from "./components/office/Keyboard"
import { Chair } from "./components/office/Chair"
// import { framerMotionConfig } from "./FramerMotionConfig"
// import { animate, useMotionValue } from "framer-motion"
// import { useEffect } from "react"
// import { useFrame } from "@react-three/fiber"
import { AnimatedReactLogo, ReactLogo } from "./components/logos/React_logo"
import { JSLogo, AnimatedJSLogo } from "./components/logos/JS_Logo"
import { AnimatedPythonLogo, PythonLogo } from "./components/logos/Python_Logo"
import { ThreeJS_Logo, AnimatedThreeJS_Logo } from "./components/logos/Threejs"
import { Postgres_Logo, AnimatedPostgres_Logo } from "./components/logos/Postgres"
import { Mongo_Logo, AnimatedMongo_Logo } from "./components/logos/Mongodb"
import { Projects } from "./components/Projects"








const Scene = ({ setSection, ...props }) => {



  return (
    <>
      <ambientLight intensity={1} />
      <directionalLight intensity={5} position={[-2, 2, 0]} />
      {/* <OrbitControls /> */}


      {/* logos */}
      <group>
        <AnimatedReactLogo />
        <AnimatedJSLogo />
        <AnimatedPythonLogo />
        <AnimatedThreeJS_Logo />
        <AnimatedPostgres_Logo />
        <AnimatedMongo_Logo />
      </group>
      {/* logos end */}


      {/* office */}
      <group
        position={[0.7, 0.5, 1]}
      >

        <group position={[3, 1, -4]} rotation-y={2.7} rotation-x={0.4} rotation-z={-0.1} scale={2}>
          <Avatar position={[0, -0.985, -1]} />



          <Desk rotation-y={4} position={[0.45, -0.3, -1.6]} />

          <Monitor
            position={[0.6, -0.227, -1.7]}
            rotation-y={-0.67}
            scale={0.2}
          />

          {/* <Mouse
            position={[0.65, -0.27, -1.35]}
            rotation={[-Math.PI / 2, 0, -0.8]}
            scale={0.0135}
          /> */}

          <Keyboard
            position={[0.4, -0.25, -1.53]}
            rotation-y={-0.65}
          />

          <Chair
            rotation-x={-Math.PI / 2}
            rotation-z={2.5}
            position={[0.01, -0.45, -1]}
            scale={0.55}
          />
        </group>

      </group>
      {/* office end */}


      <Projects setSection={setSection} />
    </>
  )
}

export default Scene