/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 monitor.glb 
Author: Marco Zakaria (https://sketchfab.com/marcoZakaria)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/computer-monitor-lowpoly-model-1d4ee42eda804b02b05b63721921c1d7
Title: Computer Monitor Lowpoly Model
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Monitor(props) {
  const { nodes, materials } = useGLTF('models/monitor.glb')
  return (
    <group {...props} dispose={null}>
      <group >
        <mesh geometry={nodes.Object_4.geometry} material={materials.Display} />
        <mesh geometry={nodes.Object_5.geometry} material={materials.Main} />
      </group>
      <mesh geometry={nodes.Object_7.geometry} material={materials.Stand} position={[0, -0.055, 0.027]} rotation={[-1.571, 0, 0]} scale={0.272} />
      <mesh geometry={nodes.Object_9.geometry} material={materials.Main_MAt} />
    </group>
  )
}

useGLTF.preload('models/monitor.glb')
