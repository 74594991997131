/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 chair.glb 
Author: Tyler P Halterman (https://sketchfab.com/tylerhalterman)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/office-chair-87a8de3461bf44848f3629fa8a8dcd68
Title: Office Chair
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Chair(props) {
  const { nodes, materials } = useGLTF('models/chair.glb')
  return (
    <group {...props} dispose={null}>
      <group >
        <mesh geometry={nodes.defaultMaterial.geometry} material={materials['01___Default']} rotation={[Math.PI / 2, 0, 0]} />
      </group>
    </group>
  )
}

useGLTF.preload('models/chair.glb')
