/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 desk.glb 
Author: bappoiscool (https://sketchfab.com/dhartney2)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/office-desk-2b13ff9ec53049568c0e7b82948772ca
Title: Office Desk
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Desk(props) {
  const { nodes, materials } = useGLTF('models/desk.glb')
  return (
    <group {...props} dispose={null}>
      <group scale={0.003}>
        <mesh geometry={nodes.desk_table_edge_0.geometry} material={materials.table_edge} />
        <mesh geometry={nodes.desk_table_main_0.geometry} material={materials.table_main} />
        <mesh geometry={nodes.desk_table_drawers_0.geometry} material={materials.table_drawers} />
        <mesh geometry={nodes.desk_lambert1_0.geometry} material={materials.lambert1} />
      </group>
      <mesh geometry={nodes.handle_handle4_0.geometry} material={materials.handle4} position={[-39.871, -4.049, -8.385]} />
      <mesh geometry={nodes.handle1_handle4_0.geometry} material={materials.handle4} position={[-39.871, 75.485, -8.385]} />
      <mesh geometry={nodes.handle2_handle4_0.geometry} material={materials.handle4} position={[-39.871, 75.485, 350.053]} />
      <mesh geometry={nodes.handle3_handle4_0.geometry} material={materials.handle4} position={[-39.871, -4.049, 350.053]} />
    </group>
  )
}

useGLTF.preload('models/desk.glb')
